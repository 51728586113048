import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { getRequest } from "../../../utils/axios";

import logo from "../../../assets/icons/logo.jpg";
import facebook from "../../../assets/icons/facebook.svg";
import whatsapp from "../../../assets/icons/whatsapp.svg";
import instagram from "../../../assets/icons/instagram.svg";
import youtube from "../../../assets/icons/youtube.svg";
import menu from "../../../assets/icons/menu.svg";
import x from "../../../assets/icons/x.svg";

import "./styles.scss";

const Header = () => {
    const [data, setData] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getRequest(`Page/GetActivePagesByCategoryTypeName/pages`);
            setData(response.data);
            localStorage.setItem('docData', JSON.stringify(response.data));
        };
        fetchData();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsSidebarOpen(false);
            }
        };

        if (isSidebarOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    const renderNavLink = (pageId) => {
        return data.filter(doc => doc.pageId === pageId).map(linkData => (
            <NavLink key={linkData.pageId} to={`/pages/${linkData.pageUrl}`} activeClassName="active">{linkData.pageTitle}</NavLink>
        ));
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <header className="b-header-wrapper">
            <div className="b-width-wrapper">
                <nav className="b-navbar">
                    <div className="logo">
                        <Link to="/"> <img src={logo} alt="logo" /> </Link>
                    </div>
                    <div className="menu-icon" onClick={toggleSidebar}>
                        <img src={menu} alt="menu" />
                    </div>
                    <div className={`headings ${isSidebarOpen ? 'hide' : ''}`}>
                        <ul>
                            <li>
                                <NavLink exact to="/" activeClassName="active">Home</NavLink>
                            </li>
                            <li>{renderNavLink(55)}</li>
                            <li>{renderNavLink(56)}</li>
                            <li>
                                <NavLink to="/events" activeClassName="active">Events</NavLink>
                            </li>
                            <li>
                                <NavLink to="/videos" activeClassName="active">Videos</NavLink>
                            </li>
                            <li>{renderNavLink(69)}</li>
                            <li>{renderNavLink(113)}</li>
                        </ul>
                    </div>
                    <div className="social-links">
                        <div className="link-1">
                            <Link to="https://www.facebook.com/indianheightscheeka" target="_blank">
                                <img src={facebook} alt="facebook" />
                            </Link>
                        </div>
                        <div className="link-2">
                            <Link to="https://api.whatsapp.com/send/?phone=919873102591&text&type=phone_number&app_absent=0" target="_blank">
                                <img src={whatsapp} alt="whatsapp" />
                            </Link>
                        </div>
                        <div className="link-3">
                            <Link to="https://www.instagram.com/tihischeeka/" target="_blank">
                                <img src={instagram} alt="instagram" />
                            </Link>
                        </div>
                        <div className="link-4">
                            <Link to="https://www.youtube.com/@tihischeeka" target="_blank">
                                <img src={youtube} alt="youtube" />
                            </Link>
                        </div>
                    </div>
                </nav>
                <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
                    <div className="close-icon" onClick={toggleSidebar}>
                        <img src={x} alt="close" />
                    </div>
                    <ul>
                        <li>
                            <NavLink exact to="/" activeClassName="active" onClick={toggleSidebar}>Home</NavLink>
                        </li>
                        <li onClick={toggleSidebar}>{renderNavLink(55)}</li>
                        <li onClick={toggleSidebar}>{renderNavLink(56)}</li>
                        <li>
                            <NavLink to="/events" activeClassName="active" onClick={toggleSidebar}>Events</NavLink>
                        </li>
                        <li>
                            <NavLink to="/videos" activeClassName="active" onClick={toggleSidebar}>Videos</NavLink>
                        </li>
                        <li onClick={toggleSidebar}>{renderNavLink(69)}</li>
                        <li onClick={toggleSidebar}>{renderNavLink(113)}</li>
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Header;
