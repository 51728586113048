import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Main from '../../layout/Main';
import Loader from "../../components/atoms/Loader";
import { getRequest } from "../../utils/axios";
import calendarIcon from "../../assets/icons/calendar-blue.svg";
import "./styles.scss";

const imageUrl = "https://dynamicproductstorage.blob.core.windows.net/gallery";
const EVENTS_PER_PAGE = 6;

const Events = () => {
    const [allEvents, setAllEvents] = useState([]);
    const [displayedEvents, setDisplayedEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await getRequest(`Page/GetActivePagesByCategoryTypeName/events`);
            const eventsData = response.data || [];
            setAllEvents(eventsData);
            setDisplayedEvents(eventsData.slice(0, EVENTS_PER_PAGE));
            localStorage.setItem('docData', JSON.stringify(eventsData));
        } catch (error) {
            console.error("Error fetching events data:", error);
            setError("Failed to load events");
        } finally {
            setLoading(false);
        }
    };

    const getImage = (event) => {
        const { featureImage, pageGallery } = event;

        if (featureImage) {
            return {
                galleryFilePath: featureImage.galleryFilePath || '',
                galleryFileName: featureImage.galleryFileName || ''
            };
        }

        if (pageGallery && pageGallery.length > 0) {
            const firstImage = pageGallery[0].gallery;
            return {
                galleryFilePath: firstImage.galleryFilePath || '',
                galleryFileName: firstImage.galleryFileName || ''
            };
        }

        return null;
    };

    const loadMoreEvents = async (event) => {
        event.preventDefault();
        if (isLoadingMore) return;

        setIsLoadingMore(true);

        try {
            const nextEvents = allEvents.slice(displayedEvents.length, displayedEvents.length + EVENTS_PER_PAGE);
            setDisplayedEvents([...displayedEvents, ...nextEvents]);
        } catch (error) {
            console.error("Error loading more events:", error);
        } finally {
            setIsLoadingMore(false);
        }
    };

    return (
        <Main className="b-events-wrapper">
            <Loader loading={loading} backdrop={true} />
            <div className="content b-width-wrapper">
                {loading ? (
                    <p>Loading events...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <>
                        <div className="cards">
                            {displayedEvents.length > 0 ? (
                                displayedEvents.map((event, index) => {
                                    const image = getImage(event);
                                    const imagePath = image ? `${imageUrl}/${image.galleryFilePath.replace(/\\/g, '/')}${image.galleryFileName}` : '';
                                    return (
                                        <div className="card" key={event.pageId || index}>
                                            <div className="image-side">
                                                {image ? (
                                                    <img
                                                        src={imagePath}
                                                        alt="event"
                                                        className="thumbnail-image"
                                                    />
                                                ) : (
                                                    <p>No image available</p>
                                                )}
                                            </div>
                                            <div className="content-side">
                                                <div className="calendar">
                                                    <div className="circle">
                                                        <img src={calendarIcon} alt="calendar" />
                                                    </div>
                                                    {new Date(event.publishDate).toLocaleDateString()}
                                                </div>
                                                <Link target="_blank"
                                                    to={{
                                                        pathname: event.pageUrl,
                                                        state: { categoryTypeName: event.categoryTypeName, pageUrl: event.pageUrl }
                                                    }}
                                                >
                                                    <h1>{event.pageName}</h1>
                                                </Link>
                                                <p className="event-description">{event.shortDescription}</p>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <p>No events found.</p>
                            )}
                        </div>
                        {displayedEvents.length < allEvents.length && (
                            <button 
                                className="load-more-btn" 
                                onClick={loadMoreEvents} 
                                disabled={isLoadingMore}
                            >
                                {isLoadingMore ? 'Loading...' : 'Load More'}
                            </button>
                        )}
                    </>
                )}
            </div>
        </Main>
    );
};

export default Events;
